import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TransactionStatus } from 'globalpayments-3ds';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BACK, CANCEL, CREATE_ACCOUNT, NEXT, UPDATE } from '../cp-common/signup-footer-controls/translate';
import { setControlMetadata } from '../helpers/utils';
import { LangPipe } from '../pipes/lang.pipe';
import { AppConfigService } from '../services/app-config.service';
import { CommunicationService } from '../services/communication.service';
import { DiscoveryStoreService } from '../services/discovery.service';
import { FrocusTrapService } from '../services/frocus-trap.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { UserFromSession } from '../services/interfaces/UserFromSession';
import { LanguageService } from '../services/language.service';
import { CPMixPanel } from '../services/mixpanel.service';
import { Payment } from '../services/models/backend-save-classes';
import { SessionService } from '../services/session.service';
import { GENERAL_SERVER_ERROR } from '../services/translation-files/translate';
import { UiFlowStateService } from '../services/ui-flow-state.service';
import { UserPaymentService } from './user-payment.service';

enum CreditCardType {
  MASTERCARD,
  VISA,
  AMEX,
  DISCOVER,
}

@Component({
  selector: 'cp-user-payment',
  templateUrl: './user-payment.component.html',
  styleUrls: ['./user-payment.component.scss']
})
export class UserPaymentComponent implements OnInit, AfterViewInit {
  isModalOpen = false;
  pressedSubmit = false;
  checkedPromo = false;
  acceptedPromo = false;
  invalidCreditCardCode = 0;
  isPromoEmpty = true;
  responsePromo: any = {};
  currencyCode = '';
  isLoading = false;
  masterObj: any = {};
  optional = false;
  orgName: string;
  cost = '10';
  costCurrencyCode = 'USD';
  formattedCost = '';
  lastComponent: Boolean;
  locale = 'en_US';
  showProgramPopup = false;
  programLink: SafeResourceUrl;
  langPipe: LangPipe;
  countryCode: '';
  ccTypeUrl: string;
  ccTypeAltText: string;
  region: string;

  signupType: string;

  ccLast4: string;
  ccLast4Cache: string;
  backButtonPreventDefault = false;
  backButtonText = null;
  nextButtonText = null;
  paypalExpressCheckout: string;
  isCC3DSecureEnabled = false;

  showBackButton = true;
  showCancelButton = false;
  showPromoCode = false;
  showSubHeader = true;
  isPayPerCharge = null;
  userFromSession: UserFromSession;

  errorTranslationKeys = {
    ccNumber: 'errorLabelCcNumber',
    ccExpirationDate: 'errorLabelCcExpirationDate',
    ccCVV: 'errorLabelCcCVV',
    ccPostalCode: 'errorLabelCcPostalCode',
  };

  @ViewChild('promoCode') promoCode: ElementRef<HTMLInputElement>;
  @ViewChild('creditCard') creditCard: ElementRef<HTMLInputElement>;

  payForm: FormGroup = new FormGroup({
    creditcard: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change'
    }),
    expirationdate: new FormControl('', {
      validators: [Validators.required, this.isExpirationCorrect()],
      updateOn: 'change'
    }),
    cvv: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change'
    }),
    postalcode: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change'
    }),
  });

  promoForm: FormGroup = new FormGroup({
    promo: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change'
    }),
  });

  constructor(
    private sanitationService: DomSanitizer,
    private zone: NgZone,
    private userService: UserPaymentService,
    private commSvc: CommunicationService,
    private mixPanel: CPMixPanel,
    private frontendConfigSvc: FrontendContentConfigService,
    private langSvc: LanguageService,
    private appConfigService: AppConfigService,
    private sessionService: SessionService,
    private focusTrapService: FrocusTrapService,
    private stateSvc: UiFlowStateService,
    private discoveryService: DiscoveryStoreService
    ) {
    this.isLoading = window.location.href.includes('/signup/paypal-success');
  }

  ngOnInit() {
    this.langPipe = new LangPipe(this.langSvc);
    this.setFormControlsMetadata();
    this.frontendConfigSvc.setBodyTitle('paymentTitle');
    this.showSubHeader = this.frontendConfigSvc.getLayoutConfig('payment').showSubHeader;
    this.locale = this.langSvc.getLocale();

    this.currencyCode = this.discoveryService.getDefaultCurrencyCode();
    this.costCurrencyCode = this.discoveryService.getDefaultCurrencyCode();
    this.masterObj = this.commSvc.getMasterObject();
    this.region = this.discoveryService.getDiscoveryData().endPoints.accounts_endpoint.value.includes('eu') ? 'EU' : '';

    if (this.region !== 'EU') {
      this.appConfigService.getConfig().subscribe(data => {
      this.paypalExpressCheckout = data.paypalExpressCheckout;
      this.isCC3DSecureEnabled = data.isCC3DSecureEnabled;
    });
    }

    this.sessionService.getUser().subscribe(user => {
      this.userFromSession = user;
      if (Array.isArray(user.billingModels) && user.billingModels.length > 1) {
        this.isPayPerCharge = false;
      } else {
        this.isPayPerCharge = Array.isArray(user.billingModels) && user.billingModels.includes('PAY_PER_CHARGE');
      }
      if (!this.masterObj['profile_zipCode'] && user?.address?.zipCode) {
        this.payForm.get('postalcode').patchValue(user.address.zipCode);
      }
    });

    this.commSvc.getComponentDefinition('payment').subscribe(componentDef => {
      if (componentDef) {
        componentDef.fields.forEach( field => {
          if (field.id === 'promoCode') {
            this.showPromoCode = true;
          }
        });
      }
    });

    this.payForm.get('postalcode').patchValue(this.masterObj['profile_zipCode']);

    if ('paymentId' in this.masterObj) {
      this.ccLast4 = this.masterObj.ccLast4;
    }

    if ('promo' in this.masterObj) {
      this.responsePromo = this.masterObj['promo'];
      this.acceptedPromo = true;
      if (!this.responsePromo.paymentRequired) {
        this.clearCardValidation();
      }
    }

    if ('processPayPalToken' in this.masterObj && this.masterObj.processPayPalToken) {
      this.isLoading = true;
    }
    // setup optional
    this.optional = this.masterObj.paymentOptional;
    this.signupType = this.masterObj.signupType;
    this.lastComponent = this.masterObj.createComponent === 'payment';

    if (this.lastComponent && !this.masterObj.signupEmail && this.frontendConfigSvc.cobrand === 'daimler') {
      this.nextButtonText = this.langPipe.transform(CREATE_ACCOUNT);
    } else {
      this.nextButtonText = this.langPipe.transform(NEXT);
    }

    if (this.commSvc.getPartnerToken() && this.signupType === 'LEASECO') {
      this.userService.getLeascoFields(this.commSvc.getPartnerToken()).subscribe(data => {
        this.orgName = data.orgName;
      });
    }
    this.commSvc.getComponentDefinition('payment').subscribe(componentDefinition => {
      if (componentDefinition) {
        this.showBackButton = componentDefinition.showBackButton;
      }
    });

    if (this.masterObj.profile_countryCode) {
      this.countryCode = this.masterObj.profile_countryCode;
    } else if (this.masterObj.selectedCountryObj && this.masterObj.selectedCountryObj.code) {
      this.countryCode = this.masterObj.selectedCountryObj.code;
    }

    this.userService.getReplenishmentAmount(this.countryCode).subscribe((resp) => {
      if (resp.initialDeposit) {
        this.cost = resp.initialDeposit;
      }

      if (resp.code) {
        this.costCurrencyCode = resp.code;
        this.currencyCode = resp.code;
      }

      this.formattedCost = new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.costCurrencyCode,
      }).format(Number(this.cost)).replace(',', '.');
    });

    this.ccTypeUrl = '/assets/images/ic_credit_generic.png';
    this.ccTypeAltText = '';
   }

  private setFormControlsMetadata() {
    setControlMetadata(
      'creditcard',
      this.payForm,
      {translatedLabel: this.langPipe.transform(this.errorTranslationKeys.ccNumber)}
    );
    setControlMetadata(
      'expirationdate',
      this.payForm,
      {translatedLabel: this.langPipe.transform(this.errorTranslationKeys.ccExpirationDate)}
    );
    setControlMetadata(
      'cvv',
      this.payForm,
      {translatedLabel: this.langPipe.transform(this.errorTranslationKeys.ccCVV)}
    );
    setControlMetadata(
      'postalcode',
      this.payForm,
      {translatedLabel: this.langPipe.transform(this.errorTranslationKeys.ccPostalCode)}
    );
  }

  ngAfterViewInit() {
    if (this.creditCard) {
      this.creditCard.nativeElement.focus();
    }
  }

  modalToggle(e?) {
    // EE_TODO: fix animation
    // EE_TODO: add class to body to not have overflow
    if (e) {
      this.isModalOpen = !e.target.dataset.close;
    } else {
      this.isModalOpen = true;
      setTimeout(() => {
        this.promoCode.nativeElement.focus();
      }, 100);
    }
  }

openModal(e) {
  this.focusTrapService.setLastFocusedElement(e.target as HTMLElement);
  this.modalToggle();
}

  closeProgramPopup(webviewData) {
    // VC: CPSRV-24763 Fix for program iframe
    this.zone.run(() => {
      this.programLink = '';
      this.showProgramPopup = false;
      this.acceptedPromo = true;
      this.checkedPromo = true;
      this.isModalOpen = false;
      this.clearCardValidation();
      this.commSvc.pushToMaster(
        {'additionalFields': webviewData},
        {'additionalFields': webviewData}
        );

      if (this.isLoading) {
        this.isLoading = false;
      }
    });
  }

  checkPromo() {
    if (this.promoForm.get('promo').value.length > 0) {
      this.isLoading = true;
      this.userService.checkPromo(this.promoForm.get('promo').value)
      .subscribe(data => {
        this.responsePromo = data;
        if (data.program && data.program.webViewLink) {
          // VC: CPSRV-24763 Fix for program iframe
          window['CP'] = {my_account: true};
          window['my_close_function'] = this.closeProgramPopup.bind(this);
          this.programLink = this.sanitationService.bypassSecurityTrustResourceUrl(data.program.webViewLink);
          this.showProgramPopup = true;
        } else {
          this.acceptedPromo = true;
          this.checkedPromo = true;
          this.isModalOpen = false;
        }
        if (!data.paymentRequired) {
          this.clearCardValidation();
        }
        this.isLoading = false;
      }, err => {
        // if promo code is not valid
        this.responsePromo = err.error;
        this.checkedPromo = true;
        this.isLoading = false;
      });
    } else {
      this.checkedPromo = true;
      this.isPromoEmpty = false;
    }
  }

  submitPaypal() {
    if(this.region !== 'EU') {
      // Spinner
      this.isLoading = true;
      const ppObj: any = {
        'amount': 0,
        'cancelUrl': `${window.location.protocol}//${window.location.host}/signup/paypal-failure${window.location.search}`,
        'currency': this.currencyCode,
        'returnUrl': `${window.location.protocol}//${window.location.host}/signup/paypal-success${window.location.search}`
      };

      this.userService.paypalSetExpressCheckout(ppObj)
      .pipe(
        catchError(() => {
          this.stateSvc.updateErrorMessage(GENERAL_SERVER_ERROR);
          return of(null);
        })
      )
      .subscribe(data => {
        if (data === null) {
          this.isLoading = false;
          return;
        }
        this.mixPanel.paymentPayPalContinue();
        this.redirectToPaypal(data.token);
      });
    } else {
      // Do Nothing for now.
    }
  }

  getCCType(s) {
    if (s.match(new RegExp('^(?:5[1-6]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)'))) {
      return CreditCardType.MASTERCARD;
    }

    if (s.match(new RegExp('^4'))) {
      return CreditCardType.VISA;
    }

    if (s.match(new RegExp('^3[47]'))) {
      return CreditCardType.AMEX;
    }

    if (s.match(new RegExp('^6(?:011|5[0-9]{2})'))) {
      return CreditCardType.DISCOVER;
    }

    return undefined;
  }

  changeCreditCardNumber(e) {
    const ccType = this.getCCType(e.target.value);

    switch (ccType) {
      case CreditCardType.AMEX:
        this.ccTypeUrl = '/assets/images/ic_credit_amex.png';
        this.ccTypeAltText = 'American Express';
        break;
      case CreditCardType.DISCOVER:
        this.ccTypeUrl = '/assets/images/ic_credit_discover_cp.png';
        this.ccTypeAltText = 'Discover';
        break;
      case CreditCardType.MASTERCARD:
        this.ccTypeUrl = '/assets/images/mc_symbol.svg';
        this.ccTypeAltText = 'Mastercard';
        break;
      case CreditCardType.VISA:
        this.ccTypeUrl = '/assets/images/ic_credit_visa.png';
        this.ccTypeAltText = 'VISA';
        break;
      default:
        this.ccTypeUrl = '/assets/images/ic_credit_generic.png';
        this.ccTypeAltText = '';
        break;
    }
  }

  redirectToPaypal(token) {
    window.location.href = this.paypalExpressCheckout + token;
  }

  skipSubmit() {
    this.mixPanel.paymentSkip();
    const backendSaveObj = new Payment({paymentId: ''});
    this.commSvc.submitFormFields({paymentId: ''}, backendSaveObj);
  }

  handleEnterKey(event: KeyboardEvent) {
    this.submitProfile();
    event.stopPropagation();
    event.preventDefault();
  }

  submitProfile() {
    this.pressedSubmit = true;
    if (this.payForm.status === 'PENDING') {
      const validaionSubscription = this.payForm.statusChanges.subscribe(status => {
        if (status === 'VALID') {
          this.executePaymentFlow();
          validaionSubscription.unsubscribe();
        } else if (status === 'INVALID') {
          validaionSubscription.unsubscribe();
        }
      });
    } else if (this.payForm.valid || this.ccLast4) {
      this.executePaymentFlow();
    }
  }

  executePaymentFlow() {
    if (this.paymentPromoPreviouslySet()) {
      const backenddata: any = {};
      if (this.masterObj.paymentId) {
        const cardNumber = this.payForm.get('creditcard').value;
        const currentLastCC4 = cardNumber.slice(cardNumber.length - 4);
        if (this.masterObj.ccLast4 && this.masterObj.ccLast4 === currentLastCC4) {
          backenddata.paymentId = this.masterObj.paymentId;
        } else {
          // credit card was changed
          delete this.masterObj.paymentId;
          delete this.masterObj.promoCode; // this will update on second iteration.
          this.executePaymentFlow();
          return;
        }
      }

      if (this.masterObj.promoCode) {
        backenddata.promoCode = this.masterObj.promoCode;
      }
      this.commSvc.submitFormFields(backenddata, backenddata);
    } else if (this.shouldValidateCC() && this.payForm.valid) {
      this.isLoading = true;
      this.authorizeCC().then(data => {
        const backendSaveObj = new Payment(data) as any;
        if (this.acceptedPromo) {
          backendSaveObj.promoCode = this.responsePromo.code;
          data.promo = this.responsePromo;
        }
        this.isLoading = false;
        this.commSvc.submitFormFields(data, backendSaveObj);
      }, err => {
        if (!err.error || typeof err.error.errorId === 'undefined') {
          this.invalidCreditCardCode = 429;
        } else {
          this.invalidCreditCardCode = err.error.errorId;
        }
        this.isLoading = false;
      });
    } else if (this.acceptedPromo) {
      this.isLoading = false;
      this.commSvc.submitFormFields({'promo': this.responsePromo}, {'promoCode': this.responsePromo.code});
    } else {
      this.isLoading = false;
    }
  }

  paymentPromoPreviouslySet() {
    return (typeof this.masterObj.paymentId !== 'undefined' || typeof this.masterObj.promoCode !== 'undefined');
  }

  shouldValidateCC() {
    return this.payForm.get('creditcard').value !== ''
        || this.payForm.get('cvv').value !== ''
        || this.payForm.get('expirationdate').value !== '';
  }

  authorizeCC() {
    const masterObj = this.commSvc.getMasterObject();
    if (!masterObj['givenName'] && this.userFromSession?.user?.givenName) {
      masterObj['givenName'] = this.userFromSession.user.givenName;
    }
    if (!masterObj['familyName'] && this.userFromSession?.user?.familyName) {
      masterObj['familyName'] = this.userFromSession.user.familyName;
    }
    const monthYearArr = this.payForm.get('expirationdate').value.split('/');
    const ccObj: any = {
      'address': {
        'zipCode': this.payForm.get('postalcode').value,
        'countryCode': this.countryCode
      },
      'cardNumber': this.payForm.get('creditcard').value,
      'currency': this.currencyCode,
      'cvv': this.payForm.get('cvv').value,
      'email': masterObj['email'],
      'expirationMonth': Number(monthYearArr[0]),
      'expirationYear': Number(monthYearArr[1]),
      'fullName': `${masterObj['givenName']} ${masterObj['familyName']}`,
      'paymentType': 'AUTO_TOP'
    };

    if (!this.isCC3DSecureEnabled) {
      return this.userService.authorizeCreditCard(ccObj, this.commSvc.getSessionToken());
    }

    return this.userService.checkCardVersion(this.masterObj, ccObj, this.commSvc.getSessionToken()).then(
      result => {
        if (result.status === TransactionStatus.ChallengeRequired
            && result.challenge?.response?.data
            && 'transStatus' in result.challenge.response.data) {
          this.mixPanel.card3dsChallenge(
            {
              'User Type': 'Driver',
              'Status': result.challenge.response.data.transStatus,
              'Server Transaction ID': result.serverTransactionId,
              'ACS Transaction ID': result.acsTransactionId
            }
          );
        }
        // test cards with statuses can be found at
        // https://developer.globalpay.com/resources/test-card-numbers#3dsecure-2
        if (result.status === TransactionStatus.AuthenticationSuccessful
            || result.status === TransactionStatus.AuthenticationAttemptedButNotSuccessful) {
          // not all cards support 3D secure check
          return this.userService.authorizeCreditCard(
            ccObj,
            this.commSvc.getSessionToken(),
            result.serverTransactionId
          );
        } else if (result.status === TransactionStatus.ChallengeRequired
            && 'transStatus' in result.challenge.response.data
            && result.challenge.response.data.transStatus === 'Y') {
          // response.challenge.response.data contains the result of challenge flow
          // see IChallengeNotificationData in globalpayments-3ds
          return this.userService.authorizeCreditCard(
            ccObj,
            this.commSvc.getSessionToken(),
            result.challenge?.response?.data?.threeDSServerTransID
          );
        } else {
          let errorId = 500;
          if (result.status !== TransactionStatus.ChallengeRequired) {
            errorId = 401;
          } else if (result.challenge?.response?.data
              && 'transStatus' in result.challenge.response.data
              && result.challenge.response.data.transStatus === 'N') {
            errorId = 504;
          }

          throw {
            error: {
              errorId: errorId
            }
          };
        }
      }
    );
  }

  // Utility function for Validation
  updateError(type) {
    return (this.payForm.get(type).invalid && (this.payForm.get(type).touched && !this.payForm.get(type).pristine))
    || (this.payForm.get(type).invalid && this.pressedSubmit);
  }

  updateErrorPromo() {
    return this.promoForm.get('promo').invalid && this.checkedPromo;
  }

  isExpirationCorrect(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value.length > 0 && control.value.indexOf('/') !== -1) {
        if (control.value[2] !== '/') {
          return { invalid: true };
        } else {
          // if exp date is past or more than 20 years than today then return false;
          const monthYear = control.value.split('/');
          const expMonth = parseInt(monthYear[0], 10);
          const expYear = parseInt(monthYear[1], 10);

          const today = new Date();
          const thisYear = parseInt(today.getFullYear().toString().substring(2), 10);

          const result = (expMonth >= 1 && expMonth <= 12
            && (expYear >= thisYear && expYear < thisYear + 20)
            && (expYear === thisYear ? expMonth >= (today.getMonth() + 1) : true));

          if (!result) {
            return {invalid: true};
          }
        }
      }
      return null;
    };
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;

    // Delete = 46, Backspace = 8, Tab = 9, Space = 32, Home = 36, End = 35
    return !(charCode !== 8 && evt.which !== 0 && (charCode < 48 || charCode > 57));
  }

  expirationSlash(evt) {
    if (evt.target.value.length === 2 && evt.keyCode !== 8) {
      evt.target.value += '/';
    }
  }

  clearCardValidation() {
    this.payForm.get('creditcard').clearValidators();
    this.payForm.get('creditcard').updateValueAndValidity();
    this.payForm.get('expirationdate').clearValidators();
    this.payForm.get('expirationdate').updateValueAndValidity();
    this.payForm.get('cvv').clearValidators();
    this.payForm.get('cvv').updateValueAndValidity();
    this.payForm.get('postalcode').clearValidators();
    this.payForm.get('postalcode').updateValueAndValidity();
  }

  showPaymentForm() {
    this.ccLast4Cache = this.ccLast4;
    this.ccLast4 = null;
    this.backButtonPreventDefault = true;
    this.showCancelButton = true;
    this.backButtonText = this.langPipe.transform(CANCEL);
    this.nextButtonText = this.langPipe.transform(UPDATE);
    this.masterObj.paymentIdCache = this.masterObj.paymentId;
    delete this.masterObj.paymentId;
  }

  cancelClicked() {
    this.ccLast4 = this.ccLast4Cache;
    this.masterObj.paymentId = this.masterObj.paymentIdCache;
    this.backButtonText = this.langPipe.transform(BACK);
    this.nextButtonText = this.langPipe.transform(NEXT);
    this.showCancelButton = false;
    if (this.backButtonPreventDefault) {
      this.backButtonPreventDefault = false;
      return;
    }
  }


  getHeaderParagraph(): string {
    if (this.signupType === 'LEASECO') {
      return this.langPipe.transform('%orgName% will pay for charging for business purposes. To pay for charging that is for personal purposes, set up a payment method.', {orgName: this.orgName} );
    }
    if (this.isPayPerCharge) {
      return this.langPipe.transform('PayAsYouGoHeadline');
    }

    return this.langPipe.transform('We’ll load your ChargePoint account with %1% the first time you use a station with a fee (set by the owner).', this.formattedCost);
   }
}
