import { LangPipe } from './../pipes/lang.pipe';
import { KitLocaleConfig } from './interfaces';
import { ANZ, EU, NA_CA, NA_MX, NA_US } from "./translate";

export const getLocaleConfig = (t: LangPipe): KitLocaleConfig => {
    return {
      privacyPolicyPath: '/privacy_policy',
      signupPath: '/signup',
      regions: [
        {
          regionCode: 'NA-US',
          regionName: t.transform(NA_US),
          ssoSubdomain: 'sso',
          defaultLocaleCode: 'en-US',
          displayCaliforniaPrivacy: true,
          californiaPrivacyPath: '/privacy_policy#CCPA',
          locales: [
            {
              localeCode: 'en-US',
              languageCode: 'en',
              localizedLanguageName: 'English',
              corporateUrl: 'www.chargepoint.com',
              nosSubdomain: 'na',
              nosCookiePrefLanguage: 'en',
              nosCookieLocale: 'en-US',
            },
            {
              localeCode: 'es-LA',
              languageCode: 'es',
              localizedLanguageName: 'Español',
              corporateUrl: 'www.chargepoint.com/es-mx',
              nosSubdomain: 'mx',
              nosCookiePrefLanguage: 'es_la',
              nosCookieLocale: 'es-LA',
            },
            {
              localeCode: 'fr-CA',
              languageCode: 'fr',
              localizedLanguageName: 'Français',
              corporateUrl: 'www.chargepoint.com/fr-ca',
              nosSubdomain: 'na',
              nosCookiePrefLanguage: 'fr_ca',
              nosCookieLocale: 'fr-CA',
            },
          ],
        },
        {
          regionCode: 'NA-US',
          regionName: t.transform(NA_MX),
          ssoSubdomain: 'sso',
          defaultLocaleCode: 'es-LA',
          locales: [
            {
              localeCode: 'es-LA',
              languageCode: 'es',
              localizedLanguageName: 'Español',
              corporateUrl: 'www.chargepoint.com/es-mx',
              nosSubdomain: 'mx',
              nosCookiePrefLanguage: 'es_la',
              nosCookieLocale: 'es-LA',
            },
          ],
        },
        {
          regionCode: 'NA-CA',
          regionName: t.transform(NA_CA),
          ssoSubdomain: 'sso-ca',
          defaultLocaleCode: 'en-CA',
          locales: [
            {
              localeCode: 'en-CA',
              languageCode: 'en',
              localizedLanguageName: 'English',
              corporateUrl: 'www.chargepoint.com/en-ca',
              nosSubdomain: 'ca',
              nosCookiePrefLanguage: 'en_ca',
              nosCookieLocale: 'en-CA',
            },
            {
              localeCode: 'fr-CA',
              languageCode: 'fr',
              localizedLanguageName: 'Français',
              corporateUrl: 'www.chargepoint.com/fr-ca',
              nosSubdomain: 'ca',
              nosCookiePrefLanguage: 'fr_ca',
              nosCookieLocale: 'fr-CA',
            },
          ],
        },
        {
          regionCode: 'EU',
          regionName: t.transform(EU),
          ssoSubdomain: 'sso-eu',
          defaultLocaleCode: 'en-GB',
          locales: [
            {
              localeCode: 'da-DK',
              languageCode: 'da',
              localizedLanguageName: 'Dansk',
              corporateUrl: 'www.chargepoint.com/da-dk',
              nosSubdomain: 'da',
              nosCookiePrefLanguage: 'da_dk',
              nosCookieLocale: 'da-DK',
            },
            {
              localeCode: 'de-DE',
              languageCode: 'de',
              localizedLanguageName: 'Deutsch',
              corporateUrl: 'www.chargepoint.com/de-de',
              nosSubdomain: 'de',
              nosCookiePrefLanguage: 'de_de',
              nosCookieLocale: 'de-DE',
            },
            {
              localeCode: 'en-GB',
              languageCode: 'en',
              localizedLanguageName: 'English',
              corporateUrl: 'www.chargepoint.com/en-gb',
              nosSubdomain: 'uk',
              nosCookiePrefLanguage: 'en_gb',
              nosCookieLocale: 'en-GB',
            },
            {
              localeCode: 'es-ES',
              languageCode: 'es',
              localizedLanguageName: 'Español',
              corporateUrl: 'www.chargepoint.com/es-es',
              nosSubdomain: 'es',
              nosCookiePrefLanguage: 'es_es',
              nosCookieLocale: 'es-ES',
            },
            {
              localeCode: 'fr-FR',
              languageCode: 'fr',
              localizedLanguageName: 'Français',
              corporateUrl: 'www.chargepoint.com/fr-fr',
              nosSubdomain: 'fr',
              nosCookiePrefLanguage: 'fr_fr',
              nosCookieLocale: 'fr-FR',
            },
            {
              localeCode: 'it-IT',
              languageCode: 'it',
              localizedLanguageName: 'Italiano',
              corporateUrl: 'www.chargepoint.com/it-it',
              nosSubdomain: 'it',
              nosCookiePrefLanguage: 'it_it',
              nosCookieLocale: 'it-IT',
            },
  
            {
              localeCode: 'nl-NL',
              languageCode: 'nl',
              localizedLanguageName: 'Nederlands',
              corporateUrl: 'www.chargepoint.com/nl-nl',
              nosSubdomain: 'nl',
              nosCookiePrefLanguage: 'nl_nl',
              nosCookieLocale: 'nl-NL',
            },
            {
              localeCode: 'nb-NO',
              languageCode: 'nb',
              localizedLanguageName: 'Norsk Bokmål',
              corporateUrl: 'www.chargepoint.com/no-no',
              nosSubdomain: 'no',
              nosCookiePrefLanguage: 'nb_no',
              nosCookieLocale: 'nb-NO',
            },
            {
              localeCode: 'pl-PL',
              languageCode: 'pl',
              localizedLanguageName: 'Polskie',
              corporateUrl: 'www.chargepoint.com',
              nosSubdomain: 'eu',
              nosCookiePrefLanguage: 'pl_pl',
              nosCookieLocale: 'pl-PL',
            },
            {
              localeCode: 'pt-PT',
              languageCode: 'pt',
              localizedLanguageName: 'Português',
              corporateUrl: 'www.chargepoint.com/pt-pt',
              nosSubdomain: 'pt',
              nosCookiePrefLanguage: 'pt_pt',
              nosCookieLocale: 'pt-PT',
            },
            {
              localeCode: 'sv-SE',
              languageCode: 'sv',
              localizedLanguageName: 'Svenska',
              corporateUrl: 'www.chargepoint.com/sv-se',
              nosSubdomain: 'sv',
              nosCookiePrefLanguage: 'sv_se',
              nosCookieLocale: 'sv-SE',
            },
            {
              localeCode: 'cs-CZ',
              languageCode: 'cs',
              localizedLanguageName: 'čeština',
              corporateUrl: 'www.chargepoint.com',
              nosSubdomain: 'eu',
              nosCookiePrefLanguage: 'cs_cz',
              nosCookieLocale: 'cs-CZ',
            },
          ],
        },
        {
          regionCode: 'ANZ',
          regionName: t.transform(ANZ),
          ssoSubdomain: 'sso-au',
          defaultLocaleCode: 'en-AU',
          locales: [
            {
              localeCode: 'en-AU',
              languageCode: 'en',
              localizedLanguageName: 'English',
              corporateUrl: 'www.chargepoint.com',
              nosSubdomain: 'au',
              nosCookiePrefLanguage: 'en_au',
              nosCookieLocale: 'en-AU',
            },
          ],
        },
      ],
    };
  }
